import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/reducers/user';
import { useLocation } from 'react-router';
import { webSDKApi } from '../../api/webSDK';
import SingleWebSDKTitle from '../../components/ui/TitleSection/Titles/SingleWebSDKTitle';
import MonthlyActivity from './components/MonthlyActivity';
import UsersTab from './UsersTab';
import InvestorProfile from './WalletTab';
import WebSDKFilters from './WebSDKFilters';
import IdentifiedProfilesTab from './IdentifiedProfilesTab';
import AttributionTab from './AttributionTab';
import './WebSDK.scss';
import ECommerceTab from './ECommerceTab';
import WebCampaignsTab from './WebCampaignsTab';
import { isEmptyObject } from '../../utils/object';

const WebSDK = () => {
  const user = useSelector(getUser);
  const { id } = useParams();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data,
    isLoading,
    isFetching,
  } = webSDKApi.useGetWebSDKByIdQuery(id);

  const [pages] = useState([
    { label: 'Crypto Shoppers', component: <ECommerceTab id={id} />, id: 1 },
    { label: 'Visitors', component: <UsersTab />, id: 2 },
    { label: 'Wallets', component: <InvestorProfile id={id} />, id: 3 },
    { label: 'Identified Profiles', component: <IdentifiedProfilesTab />, id: 4 },
    { label: 'Attribution', component: <AttributionTab />, id: 5 },
    { label: 'Web Campaigns', component: <WebCampaignsTab id={id} />, id: 6 },
  ]);

  const selectedTab = ((!isEmptyObject(user) && user.web_campaign === false) && searchParams.get('tab') === 'Web Campaigns') ? 'Crypto Shoppers' : searchParams.get('tab') || 'Crypto Shoppers';

  return (
    <>
      {!(isLoading || isFetching) && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {data?.website_name || ''}
            {' '}
            Insights
            {' '}
            - Absolute Labs Platform
          </title>
        </Helmet>
      )}
      <SingleWebSDKTitle isWebLoading={isLoading || isFetching} trackedData={data} />
      <div className="asset-section">
        <MonthlyActivity isLoading={isLoading || isFetching} />
      </div>
      <WebSDKFilters />
      <div className="asset-section">
        <div className="title-gap asset-navigation">
          <ul className="nav nav-pills gap-2">
            {pages.map((elem) => (
              <li
                role="presentation"
                className="nav-item cursor-pointer"
                key={elem.id}
                onClick={() => {
                  setSearchParams({
                    tab: elem.label,
                    scroll: false,
                  });
                }}
              >
                <Link
                  className={`nav-link ${
                    elem.label === selectedTab ? 'active' : ''
                  }`}
                  to={pathname}
                >
                  {elem.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="dropdown-divider w-100" />
        {pages.find((page) => page.label === selectedTab)?.component}
      </div>
    </>
  );
};

export default WebSDK;
