import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './Profile.module.scss';
import { webSDKApi } from '../../../api/webSDK';
import uniqueId from '../../../utils/uniqueId';
import WalletDetected from '../../../assets/icons/trackedWebsite/walletDetected.svg?url';
import { selectWebSDKFilterParam } from '../../../store/reducers/webSDK';
import WidgetCard from '../../../components/ui/WidgetCard';
import { getCardValue } from '../../../utils/values';
import ECommerceContainer from './ECommerceContainer';
import PagesList from './PageList';

const ECommerceTab = ({ id }) => {
  const [widgetData, setWidgetData] = useState([]);
  const [containerData, setContainerData] = useState([]);
  const filterParams = useSelector(selectWebSDKFilterParam);
  const {
    currentData: eCommerceStatsData,
    isLoading: eCommerceStatsIsLoading,
    isFetching: eCommerceStatsIsFetching,
  } = webSDKApi.useGetWebSDKECommerceStatsQuery({
    id,
    ...filterParams,
  });

  useEffect(() => {
    if (eCommerceStatsData) {
      setWidgetData([
        {
          title: 'Likelihood of Purchase',
          value: eCommerceStatsData?.likelihood_of_purchase_bump
            ? `${getCardValue(eCommerceStatsData?.likelihood_of_purchase_bump)}x`
            : '-',
          icon: WalletDetected,
        },
        {
          title: 'Time to Purchase',
          // temporary fix
          value: eCommerceStatsData?.time_to_purchase_improvement && eCommerceStatsData?.time_to_purchase_improvement !== '-'
            ? `${getCardValue(eCommerceStatsData?.time_to_purchase_improvement)}% shorter`
            : '-',
          icon: WalletDetected,
        },
        {
          title: 'Detected Wallets',
          value: getCardValue(eCommerceStatsData?.detected_wallets),
          icon: WalletDetected,
        },
        {
          title: 'Est.Total Wallets',
          value: getCardValue(eCommerceStatsData?.est_total_wallets),
          icon: WalletDetected,
          info: 'Based on the adoption rate over compatible devices detected, applied to all unique visitors of the website.'
        },
      ]);
      setContainerData([
        {
          title: 'Conversion Likelihood',
          value: eCommerceStatsData?.conversion_likelihood,
        },
        {
          title: 'Web 3 Adoption per Visitor Type',
          value: eCommerceStatsData?.web3_adoption_by_type,
        },
        {
          title: 'Web 3 Adoption by Country',
          value: eCommerceStatsData?.top_countries,
        }]
      )
    }
  }, [eCommerceStatsData]);

  return (
    <div className={styles.wrapper}>
      <div className="d-flex w-100 gap-4 pb-4">
        {(widgetData.length ? widgetData : [{}, {}, {}, {}, {}]).map((item) => (
          <WidgetCard
            key={uniqueId('website-widget')}
            icon={item.icon}
            info={item.info}
            growth={item.growth}
            title={item.title}
            value={item.value}
            isLoading={eCommerceStatsIsLoading || eCommerceStatsIsFetching}
          />
        ))}
      </div>
      <div className="row pb-4">
        {(containerData.length ? containerData : [{}, {}, {}]).map((elem) => (
          <ECommerceContainer
            title={elem.title}
            isLoading={eCommerceStatsIsLoading || eCommerceStatsIsFetching}
            data={elem.value}
          />
        ))}
      </div>
      <div className="row pb-4">
        <PagesList />
      </div>
    </div>
  );
};

export default ECommerceTab;
