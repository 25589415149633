import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConditionsData, getConditionsSelectedValues, removeCondition } from '../../../../store/reducers/campaign';
import { conditionsConfig } from '../../options';
import Trash from '../../../../assets/icons/trash.svg';
import DateCondition from '../Conditions/DateCondition';
import { showErrorMessage } from '../../../../components/base/Notifications';
import Select from '../Conditions/Select';
import MultiSelect from '../Conditions/MultiSelect';
import TextWithValue from '../Conditions/TextWithValue';
import DropdownInputList from '../Conditions/DropdownInputList';
import LabeledAsyncMultiSelect from '../Conditions/LabeledAsyncMultiSelect';
import styles from './ConditionBlock.module.scss';

const ConditionBlock = ({ id }) => {
  const dispatch = useDispatch();
  const data = useSelector(getConditionsData);
  const selectedData = data[id];
  const values = useSelector(getConditionsSelectedValues);
  const selectedValues = values[id];
  const selectedConditionData = conditionsConfig.find((condition) => condition.id === id);

  const handleRemove = () => {
    dispatch(removeCondition({conditionId: id}));
  }

  const getValue = (label) => {
    if (selectedValues) {
      return selectedValues[label];
    }
    showErrorMessage('Something went wrong. Please, refresh the page and try again.')
  }

  const getData = (label) => {
    if (selectedData) {
      return selectedData[label];
    }
    showErrorMessage('Something went wrong. Please, refresh the page and try again.')
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        {selectedConditionData.label}
        <button className={styles.trash} type='button' onClick={handleRemove}><Trash /></button>
      </div>
      <div className={styles.content}>
        {selectedConditionData.inputs.map((input) => {
          if (input.type === 'date') {
            return <DateCondition 
                      key={`${id}${input.label}`}
                      conditionId={id} 
                      labelText={input.displayedLabel} 
                      label={input.label} 
                      value={getValue(input.label)} 
                    />
          }
          if (input.type === 'dropdown') {
            return <Select 
                      key={`${id}${input.label}`}
                      conditionId={id} 
                      label={input.label} 
                      value={getValue(input.label)} 
                      data={getData(input.label)}
                    />
          }
          if (input.type === 'multiSelect') {
            return <MultiSelect 
                      key={`${id}${input.label}`}
                      conditionId={id} 
                      label={input.label} 
                      value={getValue(input.label)} 
                      data={getData(input.label)}
                    />
          }
          if (input.type === 'textWithValue') {
            return <TextWithValue 
                      key={`${id}${input.label}`}
                      conditionId={id} 
                      label={input.label} 
                      value={getValue(input.label)} 
                      textBefore={input.textBefore}
                      textAfter={input.textAfter}
                      max={input.max ? input.max : null}
                    />
          }
          if (input.type === 'dropdownAndInputList') {
            return <DropdownInputList
                      key={`${id}${input.label}`}
                      conditionId={id} 
                      label={input.label} 
                      value={getValue(input.label)}
                      data={getData(input.label)}
                   />
          }
          if (input.type === 'labeledAsyncMultiSelect') {
            return <LabeledAsyncMultiSelect 
                      key={`${id}${input.label}`}
                      conditionId={id} 
                      label={input.label} 
                      displayedLabel={input.displayedLabel}
                      value={getValue(input.label)}
                      type={input.dataType}
                      exclusionValue={input.exclusionLabel ? getValue(input.exclusionLabel) : null}
                   />
          }
        })}
        </div>
    </div>
  )
}

export default ConditionBlock;
