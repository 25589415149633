import React from 'react';
import styles from './DropdownMenu.module.scss';

const DropdownMenu = ({ menuData, breakWord = true }) => (
  <div className={styles.wrapper}>
    {menuData?.map((menuItem, i) => (
      <div key={menuItem.name}>
        <div
          role="presentation"
          className={styles.item}
          onClick={(e) => {
            e.preventDefault();
            if (menuItem.id) {
              menuItem.action(menuItem.id);
            } else {
              menuItem.action(menuItem.name.includes('My Assets') ? 'asset' : 'watchlist');
            }
          }}
        >
          {menuItem.icon && (
            <img src={menuItem.icon} alt="icon" width="16" height="16" />
          )}
          <div className={`${breakWord ? '' : 'text-keep-all'} ${styles.name}`}>{menuItem.name}</div>
        </div>
        {i !== menuData.length - 1 && (
          <div className={styles.divider} />
        )}
      </div>
    ))}
  </div>
);

export default DropdownMenu;
