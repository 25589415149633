import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { audiencesApi } from '../../../api/audiences';
import { convertToReadableFormat } from '../../../tools/NumberConverterTool';
import { counter } from '../../../tools/Counter';
import DownloadModal from '../../../components/ui/modals/DownloadModal';
import styles from './List.module.scss';
import { supportedBlockchains } from '../../../utils/supportedBlockchains';
import tableStyles from '../../../components/base/Table/Table.module.scss';
import Table from '../../../components/base/Table';
import { setSelectedAsset } from '../../../store/reducers/app';
import ItemPreview from '../../../components/ui/ItemPreview';
import ConfirmModal from '../../../components/ui/modals/ConfirmModal';
import { aliasData, aliasTooltipInfo } from '../../../utils/wallets';
import Tooltip from '../../../components/ui/Tooltip';
import ChainList from '../../../components/base/ChainLogo/ChainList';
import AgeDate from '../../../components/ui/Table/AgeDate';
import Export from '../../../components/ui/Buttons/Export';

const List = ({ walletCounts }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [isOpen, setIsOpen] = useState(false);
  const [order, setOrder] = useState(searchParams.get('orderBy') || 'total_portfolio_value');
  const [desc, setDesc] = useState(searchParams.get('order') !== 'false');
  const [limit, setLimit] = useState(searchParams.get('perPage') || 25);
  const [tableData, setTableData] = useState([]);

  const {
    data: walletsData,
    isLoading: isWalletsDataLoading,
    isFetching: isWalletsDataFetching,
  } = audiencesApi.useGetSegmentWalletsQuery(
    {
      id, limit: +limit, offset: (page - 1) * (+limit), orderBy: desc ? 'desc' : 'asc', order,
    },
  );

  const [getSegmentWalletCSV, result] = audiencesApi.useGetSegmentWalletsCSVMutation();

  const header = [
    {
      field: 'address_name',
      title: 'Address Name',
    },
    {
      field: '',
      title: 'Alias',
    },
    {
      field: 'total_portfolio_value',
      title: 'Portfolio Value',
    },
    {
      field: 'first_txn_timestamp',
      title: 'Wallet Age',
    },
    {
      field: '',
      title: <div className="d-flex justify-content-center">Active On</div>,
    },
  ];

  useEffect(() => {
    if (walletsData?.length) {
      const temp = walletsData.map((elem, index) => ({
        address_name: (
          <>
            <span
              className={`d-block ${tableStyles.underline_on_hover}`}
              data-for={`Name_${elem.ALID || elem.address}`}
              data-tip
            >
              <Link className="disable-link-styles" to={`/profile/${elem.ALID || elem.address}`}>
                <div
                  role="presentation"
                  onClick={() => {
                    setSearchParams({
                      ...Object.fromEntries([...searchParams]),
                      scroll: window.scrollY,
                    });
                    dispatch(setSelectedAsset({}));
                  }}
                  className={`${styles.name} text-truncate`}
                >
                  {elem.address_name || elem.address}
                </div>
              </Link>
            </span>
            <ItemPreview
              id={`Name_${elem.ALID || elem.address}`}
              data={{
                id: elem.address,
                name: elem.address,
                type: 'wallet',
                alid: elem.ALID || '',
              }}
            />
          </>
        ),
        alias: (
          <div className="d-flex gap-2 align-items-baseline">
            {elem?.alias
              ? (
                <>
                  <span
                    data-for={`alias ${index}`}
                    data-tip
                  >
                    {aliasData[elem.alias_type]}
                  </span>
                  <Tooltip info={aliasTooltipInfo[elem.alias_type]} id={`alias ${index}`} place="top" />
                  <span>{elem.alias}</span>
                </>
              )
              : '-'}
          </div>
        ),
        total_portfolio_value: `${elem.total_portfolio_value < 0 ? '?'
          : (elem.total_portfolio_value !== null
            ? `${convertToReadableFormat(elem.total_portfolio_value)} USD` : '0 USD')}`,
        first_txn_timestamp: <AgeDate date={elem.first_txn_timestamp} defaultText="No Transactions" isAge />,
        network: (
          <ChainList chains={supportedBlockchains(elem.active_on)} small />
        ),
      }));
      setTableData(temp);
    }
  }, [walletsData]);

  useEffect(() => {
    if (result.isLoading) {
      setIsOpen(true);
    }
    if (result.isSuccess && result.data) {
      const handleOpen = () => {
        setTimeout(() => {
          window.location.replace(result.data[0].link);
        }, 1000);
      };
      handleOpen();
    }
    if (result.error?.status === 403) {
      setIsOpen(false);
    }
    return () => clearTimeout();
  }, [result]);

  return (
    <div className={`${styles.wrapper}`}>
      <div className={`title-h4-bold ${styles.adaptive}`}>
        Profile List
      </div>
      <div className={`${styles.showing} d-flex d-flex justify-content-between align-items-center my-2`}>
        {counter(walletCounts, page, limit)}
        <Export
            onClick={() => getSegmentWalletCSV({
              id,
              wait: true,
            })}
            tooltip={"Export Profile List to CSV"}
            tooltipWarnings={["No more than 50,000 profiles will be downloaded"]}
          />
      </div>
      <div className="w-100">
        <Table
          total={walletCounts}
          data={tableData}
          desc={desc}
          setDesc={setDesc}
          ordering={order}
          setOrdering={setOrder}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          headers={header}
          isLoading={isWalletsDataLoading || isWalletsDataFetching}
          min={25}
        />
      </div>
      {isOpen && result?.error?.status !== 403 ? <DownloadModal result={result} setIsOpen={setIsOpen} /> : null}
      {result?.error?.status === 403 ? (
        <ConfirmModal
          title="We are sorry..."
          description="The feature you’re trying to use has restricted access.
                 Please reach out to your Customer Success Manager."
          buttonName="Okay"
          onSubmit={() => result.reset()}
        />
      ) : null}
    </div>
  );
};

export default List;
