import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { localeStorageCache } from '../../utils/localeStorage';

const BASE_API = 'api/web-campaigns';

export const webCampaignApi = createApi({
  reducerPath: 'webCampaignAPI',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const authKey = localeStorageCache.getAuth0Key();
      const authData = localeStorageCache.get(authKey);

      if (authData && authData.body) {
        const { id_token: idToken } = authData.body;
        if (idToken) {
          headers.set('Authorization', `Bearer ${idToken}`);
        }
      }
      return headers;
    },
    baseUrl: import.meta.env.VITE_APP_SERVER_DOMAIN,
  }),
  tagTypes: ['Campaign'],
  endpoints: (build) => ({
    getCampaigns: build.query({
      query: () => ({
        url: `${BASE_API}/`,
        method: 'GET',
      }),
      providesTags: () => ['Campaign'],
    }),
    getCampaignById: build.query({
      query: ({ id }) => ({
        url: `${BASE_API}/${id}/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['Campaign'],
    }),
    addCampaign: build.mutation({
      query: (body) => ({
        url: `${BASE_API}/`,
        method: 'POST',
        body,
      }),
      providesTags: () => ['Campaign'],
    }),
    updateCampaign: build.mutation({
      query: ({id, body}) => ({
        url: `${BASE_API}/${id}/`,
        method: 'PATCH',
        body,
      }),
      providesTags: () => ['Campaign'],
    }),
    deleteCampaign: build.mutation({
      query: ({ id }) => ({
        url: `${BASE_API}/${id}/`,
        method: 'DELETE',
      }),
      providesTags: () => ['Campaign'],
    }),
    getCampaignAiSummary: build.mutation({
      query: (body) => ({
        url: `${BASE_API}/configuration-ai-summary/`,
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['Campaign'],
    }),
    stopCampaign: build.mutation({
      query: ({ id }) => ({
        url: `${BASE_API}/${id}/stop/`,
        method: 'POST',
      }),
      providesTags: () => ['Campaign'],
    }),
    resumeCampaign: build.mutation({
      query: ({ id }) => ({
        url: `${BASE_API}/${id}/continue/`,
        method: 'POST',
      }),
      providesTags: () => ['Campaign'],
    }),
  }),
});