import React, {useMemo} from 'react';
import Loader from '../../../../components/base/Loader';
import NoData from '../../../../components/base/NoData';
import HorizontalValueBarChart from '../../../../components/ui/HorizontalBarChart/ValueBased';
import LogoDisplay from '../../../../components/ui/modals/SearchModal/LogoDisplay';

const getLabel = (key) => {
  switch (key) {
    case "crypto_visitor":
      return "Crypto visitor"
    case "non_crypto_visitor":
      return "Non-crypto visitor"
    case "visitor_with_2_plus_wallets":
      return "Visitors with 2+ wallets"
    case "visitor_with_3_plus_wallets":
      return "Visitors with 3+ wallets"
    case "visitor_with_btc_wallets":
      return "Visitors with a Bitcoin wallet"
    case "returning_visitors":
      return "Returning visitor"
    case "repeat_buyers":
      return "Repeat Buyer"
    case "avg_visitors":
      return "Average visitor"
    case "buyers":
      return "Buyer"
    case "logged_in_customers":
      return "Logged-in Customers"
    default:
      return "";
  }
}

const ECommerceContainer = ({
  title, isLoading, data
}) => {
  const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
  const containerContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="p-5">
          <Loader />
        </div>
      );
    }
    let parsedData;
    if (data) {
      const filteredData = Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null));
      if (title === 'Conversion Likelihood' || title === 'Web 3 Adoption per Visitor Type') {
        parsedData = Object.keys(filteredData).map((key) => ({
          name: getLabel(key),
          value: filteredData[key] ? filteredData[key].toFixed(1) : null
        }))
      } else if (title === 'Web 3 Adoption by Country') {
        parsedData = data.map((elem) => ({
          name: regionNamesInEnglish.of(elem.country),
          value: elem ? elem.adoption.toFixed(1) : null,
          logo: (
            <span className="d-flex align-items-center gap-2">
              <LogoDisplay
                logo={`/trackedWebsite/countryFlags/${elem.country.toLowerCase()}.svg`}
                size="40px"
                asset="address"
                position="static"
              />
            </span>
          ),
        }))
      }
      parsedData = parsedData.sort(({value:a}, {value:b}) => b-a)
    }

    return parsedData && parsedData.length
      ? (
        <HorizontalValueBarChart
          title={title}
          data={parsedData}
        />
      )
      : (
        <div className="d-flex justify-content-center align-items-center">
          <NoData />
        </div>
      )
  }, [data, isLoading, title]);

  return (
    <div className={'col-sm-12 col-md-6 col-lg-4'}>
      {containerContent}
    </div>
  );
}

export default ECommerceContainer