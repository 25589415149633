import React from 'react';
import ConditionBlock from '../Components/ConditionBlock';
import styles from './Conditions.module.scss';
import ConditionSeparator from '../Components/ConditionSeparator';

const Conditions = ({ conditions }) => {
  return (
    <div className={styles.wrapper}>
      {conditions.map((condition, index) => (
        <>
          <ConditionBlock id={condition} />
          {index < conditions.length - 1 && <ConditionSeparator />}
        </>
      ))}
    </div>
  )
}

export default Conditions
