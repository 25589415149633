import React from 'react';
import Select from 'react-select';
import { placeholderStyle } from '../../../AddSegment/Condition/Condition.constants';
import styles from './TextWithDropdown.module.scss';

const TextWithDropdown = ({ text, value, data, onChange, nullable = false, nullableText = '' }) => {
  const handleChange = (newValue) => {
    if (onChange) {
      onChange(newValue);
    }
  }

  const customStyles = {
    ...placeholderStyle,
    option: (provided, state) => ({
      ...provided,
      color: state.data.value === null ? '#90A0B7' : provided.color,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.value === null ? '#90A0B7' : provided.color,
    }),
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.text}>{text}</span>
      <Select
        styles={customStyles}
        maxMenuHeight={194}
        className="w-100"
        value={value}
        options={[
          ...(nullable ? [{ value: null, label: nullableText }] : []),
          ...data
        ]}
        hideSelectedOptions
        onChange={handleChange}
        isShowValue
      />
    </div>
  )
}

export default TextWithDropdown
