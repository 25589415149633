import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../../components/ui/modals/ConfirmModal/ConfirmModal.module.scss';
import { showErrorMessage } from '../../../../components/base/Notifications';
import { setFormName, setFormUrl, selectFormUrl } from '../../../../store/reducers/forms';
import Modal from '../../../../components/base/Modal';
import ModalTitle from '../../../../components/base/Modal/ModalTitle';
import ModalFooter from '../../../../components/base/Modal/ModalFooter';

export const SaveFormNameModals = ({
  onCancel, onSubmit, formName, status,
}) => {
  const dispatch = useDispatch();
  const formurl = useSelector(selectFormUrl);

  const generateRandomString = () => {
    const chars = 'abcdefghjkmnpqrstuvwxyz23456789';
    let resulting = '';
    for (let i = 0; i < 5; i += 1) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      resulting += chars[randomIndex];
    }
    return resulting;
  };

  useEffect(() => {
    if (!formurl || formurl === '') {
      const gen = generateRandomString();
      dispatch(setFormUrl(gen));
    }
  }, []);

  const handleSave = () => {
    if (!formName) {
      showErrorMessage('Form Name cannot be empty');
      return;
    }
    onSubmit(status);
    onCancel();
  };

  return (
    <Modal onCancel={onCancel}>
      <ModalTitle title="Name your form" />
        <div className={`${styles.description} mb-0`}>
          <span className={`${styles.description} mb-0`}>
            To save a form you will need to give it a name.
          </span>
        </div>
        <div className="mb-0">
          <input
            type="text"
            className="form-control mb-0"
            id="formName"
            placeholder="Form Name"
            value={formName}
            onChange={(e) => dispatch(setFormName(e.target.value))}
          />
        </div>
        <ModalFooter>
          <button
            type="button"
            className="outline-button border border-1"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="regular-button"
            onClick={handleSave}
          >
            Save
          </button>
        </ModalFooter>
    </Modal>
  );
};
