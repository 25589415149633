import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { 
  getCampaign, 
  getSelectedConditions, 
  reset, 
  setCloseIfButtonClicked, 
  setConditionData, 
  setHideAfterSubmission, 
  setHideIfButtonClicked, 
  setSelectedValue, 
  setTitle as setStoredTitle, 
  setTimeBetweenDisplay, 
  setTimesDisplay 
} from '../../store/reducers/campaign';
import { webCampaignApi } from '../../api/webCampaign';
import { getSelectedCountries, getSelectedLanguages, parseButtons, parseCampaignToBE } from './helper';
import EditableTitle from '../../components/base/EditableTitle';
import Loader from '../../components/base/Loader';
import Footer from './Footer';
import Selection from './Selection';
import ConditionButtons from './ConditionButtons';
import AdvancedSettings from './AdvancedSettings';
import Conditions from './Conditions';
import styles from './FormCampaigns.module.scss'
import { getUser } from '../../store/reducers/user';
import { conditionsConfig } from './options';
import { isEmptyObject } from '../../utils/object';
import { showErrorMessage, showSuccessMessage } from '../../components/base/Notifications';
import { formsApi } from '../../api/forms';
import { getIsStaticForm } from '../../utils/form';
import RoundSpinner from '../../components/base/RoundSpinner';
import FormDraftModal from './FormDraftModal';

const FormCampaigns = () => {
  const user = useSelector(getUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const conditions = useSelector(getSelectedConditions);
  const storedCampaign = useSelector(getCampaign);
  const { state } = useLocation();
  const [sourceFormId, setSourceFormId] = useState(null);
  const [sourceWebsiteId, setSourceWebsiteId] = useState(null);
  const isEditing = state?.edit || false;
  const isDuplicating = state?.duplicate || false;
  const duplicateId = state?.id;
  const [title, setTitle] = useState('');
  const [addCampaign, addCampaignResult] = webCampaignApi.useAddCampaignMutation();
  const [updateCampaign, updateCampaignResult] = webCampaignApi.useUpdateCampaignMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showFormDraftModal, setShowFormDraftModal] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(reset());
    }
  }, [])

  const {
    data: campaignData,
    isLoading: isCampaignLoading,
    isFetching: isCampaignFetching,
  } = webCampaignApi.useGetCampaignByIdQuery(
    {
      id : id || duplicateId
    }, 
    { skip: !((id && isEditing) || (duplicateId && isDuplicating)) }
  );

  const {
    currentData: formData,
    isLoading: formLoading,
    isFetching: formFetching,
  } = formsApi.useGetFormByIdQuery(
    {
      id: campaignData?.form?.id,
    },
    { skip: !campaignData || !campaignData?.form?.id },
  );

  const isLoading = isCampaignLoading || isCampaignFetching || formLoading || formFetching;

  useEffect(() => {
    if (campaignData && formData) {
      if(campaignData.form) {
        setSourceFormId(campaignData.form.id);
      }
      if(campaignData.website) {
        setSourceWebsiteId(campaignData.website.id);
      }
      if (campaignData.name) {
        if (isEditing) {
          setTitle(campaignData.name);
          dispatch(setStoredTitle(campaignData.name));
        } else {
          setTitle(`${campaignData.name}(1)`);
          dispatch(setStoredTitle(`${campaignData.name}(1)`));
        }
      }
      if (campaignData.max_display_count) {
        dispatch(setTimesDisplay(campaignData.max_display_count));
      }
      if (campaignData.min_display_delay) {
        dispatch(setTimeBetweenDisplay(campaignData.min_display_delay));
      }

      const isStaticForm = getIsStaticForm(formData);
      if (!isStaticForm) {
        dispatch(setHideAfterSubmission(campaignData.stop_displaying_if_submitted))
      } else {
        const buttonList = parseButtons(formData?.field_settings?.find((page) => page.pageName === 'main-form')?.fields.filter((f) => f.type === 'button'));
        const buttonsWithNull = [
          { value: null, label: 'Disabled', index: 0 }, 
          ...buttonList
        ];

        const closeButton = buttonsWithNull.find((b) => b.index === campaignData.close_modal_if_button_clicked);
        dispatch(setCloseIfButtonClicked(closeButton));

        const stopButton = buttonsWithNull.find((b) => b.index === campaignData.stop_displaying_if_button_clicked);
        dispatch(setHideIfButtonClicked(stopButton));
      }
      if (campaignData.start_at &&  campaignData.end_at) {
        dispatch(setSelectedValue({ 
          conditionId: 'dates', 
          label: 'startDate', 
          value: new Date(campaignData.start_at)
        }));
        dispatch(setSelectedValue({ 
          conditionId: 'dates', 
          label: 'endDate', 
          value: new Date(campaignData.end_at)
        }));
      }
      if (campaignData.conditions.hasOwnProperty('scroll_level')) {
        dispatch(setSelectedValue({ 
          conditionId: 'scroll', 
          label: 'level', 
          value: campaignData.conditions.scroll_level 
        }));
      }
      if (campaignData.conditions.hasOwnProperty('time_on_page')) {
        dispatch(setSelectedValue({ 
          conditionId: 'time', 
          label: 'timeSpent', 
          value: campaignData.conditions.time_on_page 
        }));
      }
      if (campaignData.conditions.hasOwnProperty('has_wallet')) {
        const walletConfig = conditionsConfig.find(c => c.id === 'wallet');
        const walletData = walletConfig.inputs[0].data;
        const selectedValue = campaignData.conditions.has_wallet ? walletData[0] : walletData[1];
        dispatch(setSelectedValue({ 
          conditionId: 'wallet', 
          label: 'wallet', 
          value: selectedValue
        }));
        dispatch(setConditionData({ 
          conditionId: 'wallet', 
          data: { wallet: walletData } 
        }));
      }
      if (campaignData.conditions.hasOwnProperty('wallet_connected')) {
        const walletConnectedConfig = conditionsConfig.find(c => c.id === 'wallet_connected');
        const walletConnectedData = walletConnectedConfig.inputs[0].data;
        const selectedValue = campaignData.conditions.wallet_connected ? walletConnectedData[0] : walletConnectedData[1];
        dispatch(setSelectedValue({ 
          conditionId: 'wallet_connected', 
          label: 'wallet', 
          value: selectedValue
        }));
        dispatch(setConditionData({ 
          conditionId: 'wallet_connected', 
          data: { wallet: walletConnectedData } 
        }));
      }
      if (campaignData.conditions.hasOwnProperty('country')) {
        const countryConfig = conditionsConfig.find(c => c.id === 'country');
        countryConfig.inputs.forEach((input) => {
          dispatch(setConditionData({ 
            conditionId: 'country', 
            data: { [input.label]: input.data } 
          }));
        })
        if (campaignData.conditions.country.hasOwnProperty('allowed')) {
          dispatch(setSelectedValue({ 
            conditionId: 'country', 
            label: 'requirements', 
            value: countryConfig.inputs[0].data[0]
          }));
          dispatch(setSelectedValue({ 
            conditionId: 'country', 
            label: 'countries', 
            value: getSelectedCountries(campaignData.conditions.country.allowed)
          }));
        } else {
          dispatch(setSelectedValue({ 
            conditionId: 'country', 
            label: 'requirements', 
            value: countryConfig.inputs[0].data[1]
          }));
          dispatch(setSelectedValue({ 
            conditionId: 'country', 
            label: 'countries', 
            value: getSelectedCountries(campaignData.conditions.country.excluded)
          }));
        }
      }
      if (campaignData.conditions.hasOwnProperty('language')) {
        const languageConfig = conditionsConfig.find(c => c.id === 'language');
        languageConfig.inputs.forEach((input) => {
          dispatch(setConditionData({ 
            conditionId: 'language', 
            data: { [input.label]: input.data } 
          }));
        })
        if (campaignData.conditions.language.hasOwnProperty('allowed')) {
          dispatch(setSelectedValue({ 
            conditionId: 'language', 
            label: 'requirements', 
            value: languageConfig.inputs[0].data[0]
          }));
          dispatch(setSelectedValue({ 
            conditionId: 'language', 
            label: 'languages', 
            value: getSelectedLanguages(campaignData.conditions.language.allowed)
          }));
        } else {
          dispatch(setSelectedValue({ 
            conditionId: 'language', 
            label: 'requirements', 
            value: languageConfig.inputs[0].data[1]
          }));
          dispatch(setSelectedValue({ 
            conditionId: 'language', 
            label: 'languages', 
            value: getSelectedLanguages(campaignData.conditions.language.excluded)
          }));
        }
      }
      if (campaignData.conditions.hasOwnProperty('segments')) {
        if (campaignData.conditions.segments.hasOwnProperty('in_segments')) {
          const segments = campaignData.conditions.segments.in_segments.map((segment) => ({
            value: segment.id,
            label: segment.name,
          }));

          dispatch(setSelectedValue({ 
            conditionId: 'segments', 
            label: 'inSegments', 
            value: segments,
          }))
        }
        if (campaignData.conditions.segments.hasOwnProperty('not_in_segments')) {
          const segments = campaignData.conditions.segments.not_in_segments.map((segment) => ({
            value: segment.id,
            label: segment.name,
          }));

          dispatch(setSelectedValue({ 
            conditionId: 'segments', 
            label: 'notInSegments', 
            value: segments,
          }))
        }
      }
      if (campaignData.conditions.hasOwnProperty('url_exclusions')) {
        const urlExclusionsConfig = conditionsConfig.find(c => c.id === 'urlExc');
        const urlExclusionsData = urlExclusionsConfig.inputs[0].fields[0].data;
        dispatch(setConditionData({ 
          conditionId: 'urlExc', 
          data: { urlRequirements: urlExclusionsData }
        }));
        const data = [];
        let rowId = Date.now();
        if (campaignData.conditions.url_exclusions.hasOwnProperty('contains')) {
          campaignData.conditions.url_exclusions.contains.forEach((url) => {
            rowId += 1;
            data.push({ 
              id: rowId,
              condition: {
                value: 'contains',
                label: 'URL contains'
              }, 
              text: url
            })
          })
        }
        rowId += 1000;
        if (campaignData.conditions.url_exclusions.hasOwnProperty('starts_with')) {
          campaignData.conditions.url_exclusions.starts_with.forEach((url) => {
            rowId += 1;
            data.push({ 
              id: rowId,
              condition: {
                value: 'starts',
                label: 'URL starts with'
              }, 
              text: url
            })
          })
        }
        rowId += 1000;
        if (campaignData.conditions.url_exclusions.hasOwnProperty('ends_with')) {
          campaignData.conditions.url_exclusions.ends_with.forEach((url) => {
            rowId += 1;
            data.push({ 
              id: rowId,
              condition: {
                value: 'ends',
                label: 'URL ends with'
              }, 
              text: url
            })
          })
        }
        dispatch(setSelectedValue({ 
          conditionId: 'urlExc', 
          label: 'urlRequirements', 
          value: data,
        }))
      }
      if (campaignData.conditions.hasOwnProperty('url_requirements')) {
        const urlRequirementsConfig = conditionsConfig.find(c => c.id === 'urlReq');
        const urlRequirementsData = urlRequirementsConfig.inputs[0].fields[0].data;
        dispatch(setConditionData({ 
          conditionId: 'urlReq', 
          data: { urlRequirements: urlRequirementsData }
        }));
        const data = [];
        let rowId = Date.now();
        if (campaignData.conditions.url_requirements.hasOwnProperty('contains')) {
          campaignData.conditions.url_requirements.contains.forEach((url) => {
            rowId += 1;
            data.push({ 
              id: rowId,
              condition: {
                value: 'contains',
                label: 'URL contains'
              }, 
              text: url
            })
          })
        }
        rowId += 1000;
        if (campaignData.conditions.url_requirements.hasOwnProperty('starts_with')) {
          campaignData.conditions.url_requirements.starts_with.forEach((url) => {
            rowId += 1;
            data.push({ 
              id: rowId,
              condition: {
                value: 'starts',
                label: 'URL starts with'
              }, 
              text: url
            })
          })
        }
        rowId += 1000;
        if (campaignData.conditions.url_requirements.hasOwnProperty('ends_with')) {
          campaignData.conditions.url_requirements.ends_with.forEach((url) => {
            rowId += 1;
            data.push({ 
              id: rowId,
              condition: {
                value: 'ends',
                label: 'URL ends with'
              }, 
              text: url
            })
          })
        }
        dispatch(setSelectedValue({ 
          conditionId: 'urlReq', 
          label: 'urlRequirements', 
          value: data,
        }))
      }
    }
  }, [campaignData, formData]);

  useEffect(() => {
    if (state?.form) {
      setSourceFormId(state.form);
    }
    if (state?.website) {
      setSourceWebsiteId(state.website);
    }
  }, [state]);

  
  useEffect(() => {
    const mainContainer = document.getElementsByClassName('main');
    if (mainContainer !== null && mainContainer.length !== 0) {
      mainContainer[0].style.padding = 0;
      mainContainer[0].style.setProperty('margin-bottom', '0', 'important');
    }

    return () => {
      if (mainContainer !== null && mainContainer.length !== 0) {
        mainContainer[0].style.padding = "0 64px";
        mainContainer[0].style.marginBottom = "16px";
      }
    }
  }, [])

  const onResultReceive = useCallback((response) => {
    setIsSubmitting(response.isLoading)
    if (response.isSuccess) {
      if (response.status === 'fulfilled') {
        if (state?.website) {
          navigate(`/tracked-website/${state.website}?tab=Web+Campaigns`)
        } else {
          navigate(`/form-analytics/${storedCampaign.form.id}?tab=Web+Campaigns`)
        }
        if (id) {
          showSuccessMessage("Campaign was successfully updated");
        } else {
          showSuccessMessage("Campaign was successfully created");
        }
      }
      response.reset();
    }
    if (response.isError) {
      if (response.error?.data?.name?.length > 0 
        && response.error.data.name[0] === 'Web campaign name must be unique') {
        showErrorMessage("This campaign name has already been used");
      } else {
        showErrorMessage("There was an error publishing a campaign");
      }
    }
  }, [dispatch, navigate, storedCampaign]);

  const onSubmit = async (statusToSave, auto = false) => {
    let canSubmit = true;
    if (!storedCampaign.title) {
      canSubmit = false;
      showErrorMessage("Name is a required field");
    }
    if (!storedCampaign.website) {
      canSubmit = false;
      showErrorMessage("Website is a required field");
    }
    if (!storedCampaign.form) {
      canSubmit = false;
      showErrorMessage("Form is a required field");
    }

    if (!canSubmit) return;

    if (!auto) {
      if (storedCampaign.form.status === 'Draft') {
        setShowFormDraftModal(true);
      }
      
      if (storedCampaign.form.status !== 'Active') {
        return;
      }
    }

    const beCampaign = parseCampaignToBE(storedCampaign);
    const payload = {
      ...beCampaign,
      status: statusToSave,
    }

    if (id) {
      await updateCampaign({ id, body: payload });
    } else {
      await addCampaign(payload);
    }
  }

  useEffect(() => {
    onResultReceive(addCampaignResult);
  }, [onResultReceive, addCampaignResult])

  useEffect(() => {
    onResultReceive(updateCampaignResult);
  }, [onResultReceive, updateCampaignResult])

  const onCancel = () => {
    navigate(-1);
  }

  const onFormPublished = () => {
    onSubmit('Active', true);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Web Campaign - Absolute Labs Platform
        </title>
      </Helmet>
      {(id && isLoading) || isEmptyObject(user) ? (
        <Loader />
      ) : (
      <>
        <div className={styles.configuration_wrapper}>
          {isSubmitting && <RoundSpinner />}
          <EditableTitle
            defaultTitle="Add campaign name"
            titleValue={title}
            setTitleValue={setTitle}
            onSave={() => dispatch(setStoredTitle(title))}
            triggerNotification={false}
          />
          <div className={styles.configuration}>
            <div className={styles.title}>
              Configuration
            </div>
            <div className={styles.horizontal_separator} />
            <div className={styles.content_wrapper}>
              <div className={styles.content}>
                <Selection sourceFormId={sourceFormId} sourceWebsiteId={sourceWebsiteId} />
                {conditions && conditions.length > 0 
                ? 
                  <Conditions conditions={conditions} />
                : 
                  <span className={styles.description}>Select one or more conditions below, or leave empty to show the form to all visitors</span>
                }
                <ConditionButtons />
                <AdvancedSettings />
              </div>
            </div>
          </div>
        </div>
        <Footer onSubmit={onSubmit} onCancel={onCancel} />
        {showFormDraftModal && 
          <FormDraftModal 
            formId={storedCampaign?.form?.id}
            closeModal={() => setShowFormDraftModal(false)} 
            onPublished={onFormPublished}
          />
        }
      </>
      )}
    </>
  )
}

export default FormCampaigns
