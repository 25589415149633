import React, { useState } from 'react';
import ChevronRight from '../../../assets/icons/chevron_right_raw.svg';
import styles from './DropdownMenu.module.scss';

const GroupedDropdownMenu = ({ menuData }) => {
  const [hoveredItem, setHoveredItem] = useState(null);

  return (
    <div className={styles.wrapper}>
      {menuData.map((groupData, i) => {
        const [group, items] = Object.entries(groupData)[0];

        return (
          <>
          <div
            key={group}
            className={styles.group}
            onMouseEnter={() => setHoveredItem(group)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <span className={styles.name}>{group}</span>
            <ChevronRight />
            {hoveredItem === group && (
              <div 
                className={styles.subMenu}
                style={{ top: i * 41 }}
              >
                {items.map((subItem, index) => (
                  <>
                  <div 
                    key={subItem.name} 
                    className={styles.item}
                    onClick={(e) => {
                      e.preventDefault();
                      subItem.action(subItem.id);
                    }}
                  >
                    <span className={styles.name}>{subItem.name}</span>
                  </div>
                  {index !== items.length - 1 && (
                    <div className={styles.divider} />
                  )}
                  </>
                ))}
              </div>
            )}
          </div>
          {i !== menuData.length - 1 && (
            <div className={styles.divider} />
          )}
          </>
        );
      })}
    </div>
  )
}

export default GroupedDropdownMenu
