import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { localeStorageCache } from '../../utils/localeStorage';

const BASE_API = 'api/audiences';

export const audiencesApi = createApi({
  reducerPath: 'audiencesAPI',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const authKey = localeStorageCache.getAuth0Key();
      const authData = localeStorageCache.get(authKey);

      if (authData && authData.body) {
        const { id_token: idToken } = authData.body;
        if (idToken) {
          headers.set('Authorization', `Bearer ${idToken}`);
        }
      }
      return headers;
    },
    baseUrl: import.meta.env.VITE_APP_SERVER_DOMAIN,
  }),
  tagTypes: ['Audiences', 'Segments'],
  endpoints: (build) => ({
    getTeamSegments: build.query({
      query: (data) => ({
        url: `${BASE_API}/team-audience/?limit=${data
          .limit}${data.offset
          ? `&offset=${data.offset}`
          : ''}${data.type
          ? `&sort=${data.type}`
          : ''}${data.field
          ? `&sort_by=${data.field}`
          : ''}${data.itemType
          ? `&item_type=${data.itemType}`
          : ''}${data.search
          ? `&search=${encodeURIComponent(data.search)}`
          : ''}${data.folders
          ? `&folders=${data.folders}`
          : ''}${data.my_audiences
          ? `&my_audiences=${data.my_audiences}`
          : ''}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
      providesTags: () => ['Segments'],
    }),

    getSegmentData: build.query({
      query: () => ({
        url: `${BASE_API}/audience-data/`,
        method: 'GET',
      }),
      providesTags: () => ['Segments'],
    }),
    createSegment: build.mutation({
      query: (body) => ({
        url: `${BASE_API}/team-audience/`,
        method: 'POST',
        body,
      }),
      providesTags: () => ['Segments'],
    }),
    getSegmentById: build.query({
      query: ({ id }) => ({
        url: `${BASE_API}/team-audience/${id}/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 20,
      providesTags: () => ['Segments'],
    }),
    changeSegment: build.mutation({
      query: (body) => ({
        url: `${BASE_API}/team-audience/${body.id}/`,
        method: 'PATCH',
        body,
      }),
      providesTags: () => ['Segments'],
    }),
    deleteSegment: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/team-audience/${id}/`,
        method: 'DELETE',
      }),
      providesTags: () => ['Segments'],
    }),
    getSegmentWalletsCount: build.query({
      query: ({ body, requestId }) => ({
        url: `${BASE_API}/audience-wallets-count/`,
        method: 'POST',
        body,
        headers: { 'X-Request-Id': ` ${requestId}` },
      }),
      providesTags: () => ['Segments'],
    }),
    getSegmentWalletsReach: build.query({
      query: (body) => ({
        url: `${BASE_API}/audience-reach/`,
        method: 'POST',
        body,
      }),
      providesTags: () => ['Segments'],
    }),
    getSegmentWalletsData: build.query({
      query: (body) => ({
        url: `${BASE_API}/audience-wallets-data/?limit=${body
          .limit}${`&offset=${body.offset}`}${`&sort=${body.sort}`}${`&sort_by=${body.sortBy}`}`,
        method: 'POST',
        body: body.userSegment,
      }),
    }),
    getWalletsCSV: build.mutation({
      query: (ard) => ({
        url: `${BASE_API}/audience-wallets-csv/${ard.id}/?limit=${ard.limit || 50000}${ard.sort
          ? `&sort=${ard.sort}`
          : ''}${ard.sortBy
          ? `&sort_by=${ard.sortBy}`
          : ''}&wait=${ard.wait}`,
        method: 'GET',
      }),
    }),
    getConditionalFlowData: build.query({
      query: () => ({
        url: `${BASE_API}/flow-conditional-data/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 20,
      providesTags: () => ['Segments'],
    }),
    getSegmentNotableInvestments: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/notable-investments/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 20,
      providesTags: () => ['Segments'],
    }),
    getSegmentInvestments: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/investor-portfolio/?desc=${arg.des}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.asset_type
          ? `&asset_type=${arg.asset_type}` : ''}${arg.page
          ? `&page=${arg.page}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}`,
      }),
      keepUnusedDataFor: 20,
      providesTags: () => ['Segments'],
    }),
    getSegmentShareInterest: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/shared-interest/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 20,
      providesTags: () => ['Segments'],
    }),
    getSegmentBuyingPower: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/buying-power/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 20,
      providesTags: () => ['Segments'],
    }),
    getSegmentAnalytics: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/analytics/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 20,
      providesTags: () => ['Segments'],
    }),
    getSegmentMedianAnalytics: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/segment-analytics/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 20,
      providesTags: () => ['Segments'],
    }),
    getSegmentWallets: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/wallets/?limit=${arg.limit}${arg.order
          ? `&order=${arg.order}`
          : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}`
          : ''}${arg.offset
          ? `&offset=${arg.offset}`
          : ''}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 20,
      providesTags: () => ['Segments'],
    }),
    getSegmentWalletsCSV: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/segments-wallets-csv/${arg.id}/?limit=${arg.limit || 50000}${arg.sort
          ? `&sort=${arg.sort}`
          : ''}${arg.sortBy
          ? `&sort_by=${arg.sortBy}`
          : ''}&wait=${arg.wait}`,
        method: 'GET',
      }),
    }),
    downloadSegmentWalletsCSV: build.mutation({
      query: (body) => ({
        url: `${BASE_API}/segments-wallets-csv/`,
        method: 'POST',
        body,
      }),
    }),
    getFlowsSegments: build.query({
      query: (name) => ({
        url: `${BASE_API}/team-audience/?item_type=segment&limit=50&search=${name}&sort_by=population&sort=desc`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
    }),
    getFlowsCustomLists: build.query({
      query: (name) => ({
        url: `${BASE_API}/team-audience/?item_type=custom list&limit=50&search=${name}&sort_by=population&sort=desc`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
    }),
    searchCustomData: build.query({
      query: (body) => ({
        url: `${BASE_API}/custom-data/${body.name}/${body.val ? `?search=${body.val}` : ''}`,
        method: 'GET',
      }),
      providesTags: () => ['Segments'],
    }),
    getInterests: build.query({
      query: (data) => ({
        url: `${BASE_API}/interests/?search=${data.search}&offset=${data.offset}&limit=${data.limit}`,
        method: 'GET',
      }),
    }),
    runRoutine: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/run-routine/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['Segments'],
    }),
    getCEXValues: build.query({
      query: (data) => ({
        url: `${BASE_API}/centralized-exchange/?offset=${data.offset}&limit=${data.limit}&search=${data.value}`,
        method: 'GET',
      }),
      providesTags: () => ['Segments'],
    }),
    getDappValues: build.query({
      query: (data) => ({
        url: `${BASE_API}/dapps/search?query=${data.value}&limit=${data.limit}&offset=${data.offset}`,
        method: 'GET',
      }),
      providesTags: () => ['Segments'],
    }),
    copySegments: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/${id}/copy/`,
        method: 'POST',
      }),
      providesTags: () => ['Segments'],
    }),
    snapshotSegments: build.mutation({
      query: (body) => ({
        url: `${BASE_API}/${body.id}/save-segment-as-custom-list/`,
        method: 'POST',
        body,
      }),
      providesTags: () => ['Segments'],
    }),
    getSegmentExchangeData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/segment/${arg.address}/cex/usage/?order=${arg.order}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}`,
      }),
    }),
    getCustomListExchangeData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/custom-list/${arg.address}/cex/usage/?order=${arg.order}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}`,
      }),
    }),
    searchCustomList: build.mutation({
      query: (name) => ({
        url: `${BASE_API}/custom-list-search/?name=${name}`,
        method: 'GET',
      }),
      providesTags: () => ['Segments'],
    }),
    submitCustomList: build.mutation({
      query: (body) => ({
        url: `${BASE_API}/custom-list/`,
        method: 'POST',
        body,
      }),
      providesTags: () => ['Segments'],
    }),
    addCustomListAddresses: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/custom-list/${arg.id}/add-address/`,
        method: 'POST',
        body: arg.addresses,
      }),
      providesTags: () => ['Segments'],
    }),
    deleteCustomList: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/custom-list/${id}/`,
        method: 'DELETE',
      }),
      providesTags: () => ['Segments'],
    }),
    deleteMultipleAudiences: build.mutation({
      query: (body) => ({
        url: `${BASE_API}/multiple-delete/`,
        method: 'DELETE',
        body: body.map((i) => ({
          object_id: i.id,
          object_type: i.type,
        })),
      }),
      providesTags: () => ['Segments'],
    }),
    getCustomList: build.query({
      query: (arg) => ({
        url: `${BASE_API}/custom-list/${arg.id}/?order=${arg.order}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['Segments'],
    }),
    getCustomListAnalytics: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/custom-list-analytics/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 20,
      providesTags: () => ['Segments'],

    }),
    getCustomListNotableInvestments: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/custom-list-notable-investments/`,
        method: 'GET',
      }),
      providesTags: () => ['Segments'],
    }),
    getCustomListBuyingPower: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/custom-list-buying-power/`,
        method: 'GET',
      }),
      providesTags: () => ['Segments'],
    }),
    getCustomListSharedInterest: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/custom-list-shared-interest/`,
        method: 'GET',
      }),
      providesTags: () => ['Segments'],
    }),
    getCustomListInvestorPortfolio: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/custom-list-investor-portfolio/?order=${arg.order}${arg.limit
          ? `&limit=${arg.limit}` : ''}${arg.asset_type
          ? `&asset_type=${arg.asset_type}` : ''}${arg.page
          ? `&page=${arg.page}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}${arg.des
          ? `&desc=${arg.des}` : ''}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 20,
      providesTags: () => ['Segments'],
    }),
    getCustomListAddressesList: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/custom-list-wallets/?limit=${arg.limit}${arg.order
          ? `&order=${arg.order}`
          : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}`
          : ''}${arg.offset
          ? `&offset=${arg.offset}`
          : ''}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 20,
      providesTags: () => ['Segments'],
    }),
    getCustomListWalletsCSV: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/custom-list-wallets-csv/${arg.id}/?limit=${arg.limit || 50000}${arg.sort
          ? `&sort=${arg.sort}`
          : ''}${arg.sortBy
          ? `&sort_by=${arg.sortBy}`
          : ''}&wait=${arg.wait}`,
        method: 'GET',
      }),
    }),
    getCustomListInsight: build.query({
      query: (id) => ({
        url: `${BASE_API}/custom-list-top-insights/${id}/`,
        method: 'GET',
      }),
    }),
    getSegmentListInsight: build.query({
      query: (id) => ({
        url: `${BASE_API}/segments-top-insights/${id}/`,
        method: 'GET',
      }),
    }),
    deleteWalletAddressFromCustomList: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/custom-list/${arg.id}/?ALID=${arg.alid}`,
        method: 'DELETE',
      }),
      providesTags: () => ['Segments'],
    }),
    updateCustomList: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/custom-list/${arg.id}/`,
        method: 'PATCH',
        body: arg.body,
      }),
      providesTags: () => ['Segments'],
    }),
    saveCustomListFromSegmentDraft: build.mutation({
      query: (body) => ({
        url: `${BASE_API}/save-custom-list/`,
        method: 'POST',
        body,
      }),
      providesTags: () => ['Segments'],
    }),
    checkENS: build.mutation({
      query: (body) => ({
        url: `${BASE_API}/address-by-ens-name/`,
        method: 'POST',
        body,
      }),
    }),
    customListReach: build.query({
      query: (id) => ({
        url: `${BASE_API}/${id}/custom-list-reach/`,
        method: 'GET',
      }),
    }),
    segmentReach: build.query({
      query: ({ id }) => ({
        url: `${BASE_API}/${id}/segment-reach/`,
        method: 'GET',
      }),
    }),
    getWalletLookup: build.query({
      query: (address) => ({
        url: `${BASE_API}/profiles/lookup/${address}/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 180,
    }),
    getSegmentDappUsageData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/segment/${arg.id}/dapps/usage/?limit=${arg.limit}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}`,
      }),
    }),
    getCustomListDappUsageData: build.query({
      query: (arg) => ({
        url: `${BASE_API}/custom-list/${arg.id}/dapps/usage/?limit=${arg.limit}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}`,
      }),
    }),
    getCustomerDataFields: build.query({
      query: () => ({
        url: `${BASE_API}/custom-dataset-fields/`,
      }),
      keepUnusedDataFor: 1,
    }),
    postCSVData: build.mutation({
      query: (data) => ({
        url: `${BASE_API}/upload-profiles/`,
        method: 'POST',
        body: data,
      }),
    }),
    getSegmentFoldersById: build.query({
      query: ({ id }) => ({
        url: `${BASE_API}/team-audience/${id}/`,
        method: 'GET',
      }),
      transformResponse: (response) => response.folders,
      providesTags: () => ['Segments'],
    }),
    getCustomListFoldersById: build.query({
      query: ({ id }) => ({
        url: `${BASE_API}/custom-list/${id}/?order=ASC`,
        method: 'GET',
      }),
      transformResponse: (response) => response.folders,
      providesTags: () => ['Segments'],
    }),
    getSegmentDevActivity: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/dev-activity/?limit=${arg.limit}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}`,
      }),
    }),
    getCustomListDevActivity: build.query({
      query: (arg) => ({
        url: `${BASE_API}/custom-list/${arg.id}/dev-activity/?limit=${arg.limit}${arg.offset
          ? `&offset=${arg.offset}` : ''}${arg.order
          ? `&order=${arg.order}` : ''}${arg.orderBy
          ? `&order_by=${arg.orderBy}` : ''}`,
      }),
    }),
  }),
});
