import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { format } from 'date-fns';
import { LoadingAvatar, LoadingLines } from '../../modals/SearchModal/LoadingList';
import DefaultIcon from '../../DefaultIcon';
import { selectSelectedAsset } from '../../../../store/reducers/app';
import { showInfoMessage } from '../../../base/Notifications';
import Copy from '../../../../assets/icons/copy_outline.svg';
import styles from '../TitleSection.module.scss';
import { getUser } from '../../../../store/reducers/user';

const SingleWebSDKTitle = ({ isWebLoading, trackedData }) => {
  const user = useSelector(getUser);
  const navigate = useNavigate();
  const selectedAsset = useSelector(selectSelectedAsset);

  const [data, setData] = useState({});
  const [logoError, setLogoError] = useState(false);

  useEffect(() => {
    if (isWebLoading && !Object.keys(selectedAsset).length) {
      setData({});
    }
    if (Object.keys(selectedAsset).length && !trackedData) {
      setData(selectedAsset);
    }
    if (trackedData) {
      setData(trackedData);
    }
  }, [isWebLoading, selectedAsset, trackedData]);

  const handleCreateCampaign = () => {
    navigate('/form/campaign', { state: { website: trackedData.id } });
  }

  return (
    <div className={styles.websdkWrapper}>
      <div className={styles.title}>
        <div className={styles.imageContainer}>
          {!data.logo && isWebLoading
            ? <LoadingAvatar size={75} />
            : data.logo && !logoError ? (
              <img
                src={data.logo}
                width="75px"
                height="75px"
                alt="logo"
                onError={() => setLogoError(true)}
              />
            ) : <DefaultIcon type="token" size="75px" />}
        </div>
        <div className="d-flex flex-column">
          <div className={styles.row}>
            <div className="d-flex flex-wrap">
              {isWebLoading
                ? (
                  <div className={`${styles.loadingLinesWrapper} w-25`}>
                    <LoadingLines align="start" />
                  </div>
                )
                : (
                  <div className="d-flex flex-column gap-2">
                    <div className={`${styles.title} ${data.website_name?.length > 10 && styles.titleAdaptive}`}>
                      {data.website_name}
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                      <div className={`${styles.web_pill} px-3 d-flex align-items-center`}>
                        Website
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <div className={styles.separator_icon} />
                        <span className={styles.contentSiteId}>
                          {data.domains || ''}
                        </span>
                        <div className={styles.separator_icon} />
                        <span className={styles.contentSiteId}>
                          Site ID:
                          {' '}
                          {data.site_id}
                        </span>
                        <Copy
                          className="cursor-pointer"
                          onClick={() => {
                            navigator.clipboard.writeText(data.site_id);
                            showInfoMessage('Site ID was copied to your clipboard');
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.author}>
                      Tracked since
                      {' '}
                      {data.created_at ? format(new Date(data.created_at), 'MMM dd, yyyy') : null}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      {user?.web_campaign ? (
        <button
          type="button"
          className="regular-button text-nowrap"
          onClick={() => handleCreateCampaign()}
          >
          Create a Web Campaign
        </button>
      ) : null}
    </div>
  );
};

export default SingleWebSDKTitle;
