import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../../components/ui/modals/ConfirmModal/ConfirmModal.module.scss';
import { setFormUrl, selectFormUrl } from '../../../../store/reducers/forms';
import Modal from '../../../../components/base/Modal';
import ModalTitle from '../../../../components/base/Modal/ModalTitle';
import ModalFooter from '../../../../components/base/Modal/ModalFooter';

export const ConfirmSaveFormModal = ({
  onCancel, onSubmit, status,
}) => {
  const dispatch = useDispatch();
  const formurl = useSelector(selectFormUrl);

  const generateRandomString = () => {
    const chars = 'abcdefghjkmnpqrstuvwxyz23456789';
    let resulting = '';
    for (let i = 0; i < 5; i += 1) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      resulting += chars[randomIndex];
    }
    return resulting;
  };

  useEffect(() => {
    if (!formurl || formurl === '') {
      const gen = generateRandomString();
      dispatch(setFormUrl(gen));
    }
  }, []);

  const handleSave = () => {
    onSubmit(status, true);
    onCancel();
  };

  return (
    <Modal onCancel={onCancel}>
      <ModalTitle title="Publish Form" />
        <div className={`${styles.description} mb-0`}>
          <div className={`${styles.subtitle} d-flex justify-content-start`}>
            Once published, a form cannot be changed.
          </div>
        </div>
        <ModalFooter>
          <button
            type="button"
            className="outline-button border border-1"
            onClick={onCancel}
          >
            Go Back
          </button>
          <button
            type="button"
            className="regular-button"
            onClick={handleSave}
          >
            Confirm
          </button>
        </ModalFooter>
    </Modal>
  );
};
