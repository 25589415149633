import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { coinApi } from '../../../api/coin';
import DevActivityList from "../../../components/ui/DevActivityList";

const DevActivity = ({blockchain, holders}) => {

  const {name: address} = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [orderBy, setOrderBy] = useState(searchParams.get('orderBy') || 'active_30d');
  const [order, setOrder] = useState(searchParams.get('order') !== 'false');
  const [limit, setLimit] = useState(searchParams.get('perPage') || 25);

  const {
    data: data,
    isLoading: isLoading,
    isFetching: isFetching,
  } = coinApi.useGetNFTDevActivityQuery(
    {
      address,
      blockchain,
      limit: +limit,
      offset: (page - 1) * (+limit),
      orderBy,
      order: order ? 'desc' : 'asc',
    },
  );
  return (
    <DevActivityList
      data={data}
      holders={holders}
      isLoading={isLoading}
      isFetching={isFetching}
      page={page}
      setPage={setPage}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      order={order}
      setOrder={setOrder}
      limit={limit}
      setLimit={setLimit}
    />
  );
};


export default DevActivity;
