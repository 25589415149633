import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/reducers/user';
import { formsApi } from '../../../api/forms';
import { getIsStaticForm } from '../../../utils/form';
import { trackFormCreated } from '../../../utils/mixpanel/mixpanelEvents';
import Modal from '../../../components/base/Modal';
import ErrorModal from './ErrorModal';
import FormModal from './FormModal';

const FormDraftModal = ({ closeModal, formId, onPublished }) => {
  const [canPublish, setCanPublish] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const user = useSelector(getUser);
  const [changeForm, resultChangeForm] = formsApi.useChangeFormMutation();

  const {
    currentData: form,
    isLoading: isFormLoading,
    isFetching: isFormFetching,
  } = formsApi.useGetFormByIdQuery(
    {
      id: formId,
    },
    { skip: !formId },
  );

  const isLoading = isFormLoading || isFormFetching;

  const handleSubmit = () => {
    if (!form || isLoading) return;

    const mainPage = form.field_settings.find((page) => page.pageName === 'main-form');
    if (mainPage && mainPage.fields.length === 0) {
      setCanPublish(false);
      return;
    }

    const isStaticForm = getIsStaticForm(form);
    if (!isStaticForm) {
      const thankYouPage = form.field_settings.find((page) => page.pageName === 'thank-you');
      if (!thankYouPage || thankYouPage.fields.length === 0) {
        setCanPublish(false);
        return;
      }
    }

    const dataToUpload = {
      name: form.name,
      allow_multiple_submissions: form.allow_multiple_submissions,
      field_settings: form.field_settings,
      accessType: '',
      style: form.style,
      errors: [],
      isUnsaved: false,
      status: 'Active',
    };

    const serializedData = JSON.stringify(dataToUpload);
    const sizeInBytes = new TextEncoder().encode(serializedData).length;
    const sizeInMB = sizeInBytes / (1024 * 1024);
    if (sizeInMB > 2) {
      setCanPublish(false);
      return;
    }

    changeForm({
      id: form.id,
      payload: dataToUpload,
    });
  }

  useEffect(() => {
    if (resultChangeForm.isLoading || resultChangeForm.isFetching) {
      setIsUpdating(true);
    } else {
      setIsUpdating(false);
    }
    if (resultChangeForm.isSuccess) {
      if (resultChangeForm.status === 'fulfilled') {
        trackFormCreated(user, resultChangeForm.data);
        if(onPublished) {
          onPublished();
        }
        closeModal();
      }
      resultChangeForm.reset();
    }
    if (resultChangeForm.isError) {
      setCanPublish(false);
    }
  }, [resultChangeForm, user]);

  return (
    <Modal onCancel={closeModal} loading={isUpdating}>
      

      {canPublish ? (
        <FormModal closeModal={closeModal} onSubmit={handleSubmit} isLoading={isLoading} />
      ) : <ErrorModal closeModal={closeModal} />}
    </Modal>
  )
}

export default FormDraftModal
