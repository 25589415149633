import React, { useEffect, useState } from 'react';
import Loader from '../../../components/base/Loader';
import styles from './DevActivityList.module.scss';
import Table from '../../../components/base/Table';
import DownloadModal from '../../../components/ui/modals/DownloadModal';
import Tooltip from '../Tooltip';
import InfoIcon from '../../../assets/icons/info_small_raw.svg';
import { ethFormat } from '../../../utils/singleAssetPage/parseData';
import ChainLogo from '../../base/ChainLogo';

const DevActivityList = ({data, holders, isLoading, isFetching, page, setPage, orderBy, setOrderBy,
                   order, setOrder, limit, setLimit}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tableData, setTableData] = useState([]);

  const header = [
    {
      field: 'blockchain',
      title: 'Blockchain name',
    },
    {
      field: 'totalDevelopers',
      title: 'Total developers',
    },
    {
      field: 'developersLast7d',
      title: 'Developers last 7d',
    },
    {
      field: 'developersLast30d',
      title: 'Developers last 30d',
    },
    {
      field: 'developersLast90d',
      title: 'Developers last 90d',
    },
  ];

  useEffect(() => {
    if (data?.length) {
      const temp = data.map((elem) => ({
        blockchain: elem?.value !==  "OVERALL" ? (
          <span className="d-flex justfy-content-between align-items-center gap-2">
            <ChainLogo chain={elem.value} square large />
            <span className="text-capitalize">
              {elem.value.toLowerCase()}
            </span>
          </span>
        ) : ( <span className="d-flex text-capitalize">
                {elem.value.toLowerCase()}
              </span>),
        totalDevelopers:(
          <div className={`${styles.elements} d-flex align-items-baseline gap-2`}>
            <span>{ethFormat(elem.active_ever, 1)}</span>
            <span className={`${styles.percentage}`}>
                  {(elem.active_ever / holders) * 100 >= 0.01
                    ? ethFormat((elem.active_ever / holders) * 100, 1)
                    : (elem.active_ever / holders) * 100 === 0 ? 0 : '<0.01'}
              %
            </span>
          </div>
        ),
        developersLast7d: (
          <div className={`${styles.elements} d-flex align-items-baseline gap-2`}>
            <span>{ethFormat(elem.active_7d, 1)}</span>
            <span className={`${styles.percentage}`}>
                  {(elem.active_7d / holders) * 100 >= 0.01
                    ? ethFormat((elem.active_7d / holders) * 100, 1)
                    : (elem.active_7d / holders) * 100 === 0 ? 0 : '<0.01'}
              %
            </span>
          </div>
        ),
        developersLast30d:(
          <div className={`${styles.elements} d-flex align-items-baseline gap-2`}>
            <span>{ethFormat(elem.active_30d, 1)}</span>
            <span className={`${styles.percentage}`}>
                    {(elem.active_30d / holders) * 100 >= 0.01
                      ? ethFormat((elem.active_30d / holders) * 100, 1)
                      : (elem.active_30d / holders) * 100 === 0 ? 0 : '<0.01'}
              %
            </span>
          </div>
        ),
        developersLast90d: (
          <div className={`${styles.elements} d-flex align-items-baseline gap-2`}>
            <span>{ethFormat(elem.active_90d, 1)}</span>
            <span className={`${styles.percentage}`}>
                    {(elem.active_90d / holders) * 100 >= 0.01
                      ? ethFormat((elem.active_90d / holders) * 100, 1)
                      : (elem.active_90d / holders) * 100 === 0 ? 0 : '<0.01'}
              %
            </span>
          </div>
        ),
    }));
      setTableData(temp);
    } else {
      setTableData([]);
    }
  }, [data]);


  return (
    <div className={`${styles.wrapper}`}>
      <div className={`d-flex gap-2 ${styles.adaptive}`}>
        <div className={styles.title}>Developer activity</div>
        <span
          data-for={"dev_title"}
          data-tip
        >
          <InfoIcon
            height="20px"
            width="20px"
            color="#C2CFE0"
          />
        </span>
        <Tooltip
          info={"Measures the unique addresses in the audience which deployed contracts on the chains listed, on mainnet"}
          id={"dev_title"}
        />
      </div>
      {(isLoading || isFetching) && !data
        ? (
          <div className="p-5">
            <Loader />
          </div>
        )
        : (
          <>
            <div className="w-100">
              <Table
                total={data ? data.length !== 0 ? data[0].number_of_paths : 0 : 0}
                data={tableData}
                desc={order}
                setDesc={setOrder}
                ordering={orderBy}
                setOrdering={setOrderBy}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                headers={header}
                isLoading={isLoading || isFetching}
                min={25}
                highlight_border={true}
              />
            </div>
            {isOpen && result?.error?.status !== 403 ? <DownloadModal result={result} setIsOpen={setIsOpen} /> : null}
          </>
        )}
    </div>
  );
};

export default DevActivityList;
