import React, { useState } from 'react';

import { convertToReadableFormat } from '../../../../../tools/NumberConverterTool';
import { supportedBlockchains } from '../../../../../utils/supportedBlockchains';
import ChainList from '../../../../../components/base/ChainLogo/ChainList';
import styles from './McapLabel.module.scss';
import Verification from '../../../../../components/ui/Verification';
import DefaultIcon from '../../../../../components/ui/DefaultIcon';
import ItemPreview from '../../../../../components/ui/ItemPreview';
import { isValidContractAddress } from '../../../../../tools/EtherTool';

const McapLabel = ({ val, type }) => {
  const [imgError, setImgError] = useState(false);
  const isManualEntry = val.value
    ? (isValidContractAddress(val.value) && (!val.platform && !val.symbol))
    : false;

  return (
    <div className={`mcapWrapper ${styles.mcapwrapper} align-items-center`}>
      <div className="d-flex">
        <div
          className="pe-2 align-items-center"
          data-for={
            type.toLowerCase() === 'nft'
              ? `preview_${val.contract_address || val.value}_${val.blockchain}`
              : type.toLowerCase() === 'token'
                ? `preview_${val.coin_id}`
                : null
          }
          data-tip="show"
        >
          {!isManualEntry && val.blockchain
            ? (val.image_url || val.image) && !imgError ? (
              <img
                width="20px"
                height="20px"
                src={val.image_url || val.image}
                alt=""
                onError={() => setImgError(true)}
              />
            ) : (
              <DefaultIcon size="20px" type={type} />
            )
            : null}
        </div>
        <div className={`d-flex ${styles.name} align-items-center`}>
          <div
            data-for={
              type.toLowerCase() === 'nft'
                ? `preview_${val.contract_address || val.value}_${val.blockchain}`
                : type.toLowerCase() === 'token'
                  ? `preview_${val.coin_id}`
                  : null
            }
            data-tip="show"
          >
            {val?.label?.slice(0, 20)}
            {val?.label?.length > 20 ? '...' : ''}
          </div>
          {type.toLowerCase() === 'nft'
            ? (
              <ItemPreview
                id={`preview_${val.contract_address || val.value}_${val.blockchain}`}
                data={{
                  address: val.contract_address || val.value,
                  type: 'nft',
                  blockchain: val.blockchain,
                  img: val.image_url || val.logo || val.image,
                  name: val.name || val.label,
                }}
              />
            )
            : null}
          {type.toLowerCase() === 'token'
            ? (
              <ItemPreview
                id={`preview_${val.coin_id}`}
                data={{
                  id: val.coin_id,
                  type: 'coin',
                }}
              />
            )
            : null}
        </div>
        {val.is_verified_on_opensea || val.verified
          ? (
            <div className={`${styles.verified} mcapVerfied align-items-center`}>
              <Verification width="16px" height="16px" />
            </div>
          )
          : null}
      </div>
      <div className="d-flex">
        {!isManualEntry && val.blockchain && val?.created_assets !== true
          ? (
            <div className={`${styles.mcap} text-nowrap mcapLabel`}>
              {`${convertToReadableFormat(val.usd_market_cap || val.market_cap)} mcap`}
            </div>
          )
          : (
            <div className={`${styles.mcap} text-nowrap mcapLabel`}>
              {val?.created_assets ? 'Created Asset' : ''}
            </div>
          )}
        {!isManualEntry && val.blockchain && val?.created_assets !== true ? (
          <div className="px-2">
            <div className="d-flex gap-1 align-items-center">
              <div className={styles.separator_icon} />
              <div className={`${styles.svg} d-flex align-items-center`}>
                <ChainList chains={supportedBlockchains(val.blockchain)} />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default McapLabel;
