import React from 'react';
import styles from './TextWithValue.module.scss';

const TextWithValue = ({ textBefore, textAfter, value, onChange }) => {
  const handleValueChange = (event) => {
    const newValue = event.target.value;
    if (/^\d*$/.test(newValue)) {
      onChange(newValue);
    }
  }
  
  return (
    <div className={styles.wrapper}>
      <span>{textBefore}</span>
      <input 
        className={styles.value} 
        type="text" 
        pattern="\d*"
        inputmode="numeric"
        value={value} 
        onChange={handleValueChange}
      />
      <span>{textAfter}</span>
    </div>
  );
};

export default TextWithValue;
